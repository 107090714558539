import React from 'react';

export const UserDepartListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['rh', 'rh_not_signer'],
    routes: [{
        path: '/departamentos-list',
        component: React.lazy(() =>
            import ('./UserDepartList'))
    }]
};