import React from 'react';
//import {authRoles} from '../../auth';

export const UpdateUsuariosConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master'],
    routes: [{
        path: '/user-details/:id',
        component: React.lazy(() =>
            import ('./UpdateUsuarios'))
    }]
};