import React from 'react';
//import {authRoles} from '../../auth';

export const CompanyListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master', 'rh', 'rh_not_signer'],
    routes: [{
        path: '/company-list',
        component: React.lazy(() =>
            import ('./CompanyList'))
    }]
};