import React from 'react';

export const ConfiguracionesFirmasConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['rh', 'rh_not_signer', 'admin', 'master'],
    routes: [{
        path: '/control-sign',
        component: React.lazy(() =>
            import ('./ConfiguracionesFirmas'))
    }]
};