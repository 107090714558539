import React from 'react';
//import {authRoles} from '../../auth';

export const DocumentUpdateConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['master', 'rh', 'rh_not_signer'],
    routes: [{
        path: '/document-type-details/:id',
        component: React.lazy(() =>
            import ('./DocumentUdpate'))
    }]
};