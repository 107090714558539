import axios from 'axios';

class EmployeeService {

    async getEmployeeByCi(ci) {
        return axios.get(
            process.env.REACT_APP_API_HOST + `/employees/identification/${ci}`,
        ).catch(function (error) {
            console.log(error);
        })
    }

    async getCertTypes() {
        return axios.get(
            process.env.REACT_APP_API_HOST + '/employees/cert-types',
        ).catch(function (error) {
            console.log(error);
        })
    }

    async getContratos() {
        return axios.get(
            process.env.REACT_APP_API_HOST + '/employees/contratos',
        ).catch(function (error) {
            console.log(error);
        })
    }

    async desactivatemtess(employeeData) {
        return axios
            .post(process.env.REACT_APP_API_HOST + "/employees/desactivate-mtess", employeeData);
    }

    async getEmployeesCert() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + '/employees/cert-list',
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployees() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + '/employees/',
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeesAll() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + '/employees/getEmployeesAll',
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async delEmployee(filter) {
        const response = await axios.delete(
            process.env.REACT_APP_API_HOST + "/employees/del", {
            params: {
                id: filter.id
            },
            headers: { "content-type": "application/x-www-form-urlencoded" }
        }
        )
            .then(response => {
                return [response.data.status, response.data.message];
            })
            .catch(error => {
                let errorMessage = "Erro inesperado";
                if (error.response !== undefined)
                    errorMessage = error.response.data.message;
                return ["error", errorMessage];
            });
    }

    async getEmployeesCount(DocumentId) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeesCount/${DocumentId}`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeById(id) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + "/employees/actualizar", {
            params: {
                id: id,
            },
            headers: { "content-type": "application/x-www-form-urlencoded" }
        }
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeByXmlId(xmlId) {
        return await axios.get(
            //process.env.REACT_APP_DOTNET_API_HOST + `/api/Employee/GetEmployeeByXmlId/${xmlId}`,
            process.env.REACT_APP_API_HOST + "/employees/xmlid", {
            params: {
                id: xmlId,
            },
            headers: { "content-type": "application/x-www-form-urlencoded" }
        }
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeByComprobanteId(xmlId) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeeByComprobanteId`, + xmlId
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeByAmonestacionId(xmlId) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeeByAmonestacionId/`, + xmlId
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeBySuspensionId(xmlId) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeeBySuspensionId/`, + xmlId
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeByApercibimientoId(xmlId) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeeByApercibimientoId/`, + xmlId
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeByPreavisoId(xmlId) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeeByPreavisoId/`, + xmlId
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeByUserEmail(email) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/GetEmployeeByUserEmail/`, + email
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getEmployeeWithUserByEmail(email) {
        return await axios.get(
            //process.env.REACT_APP_DOTNET_API_HOST + `/api/Employee/GetEmployeeWithUserByEmail/${email}`,
            process.env.REACT_APP_API_HOST + "/employees/profile" +
            (email ? `?email=${email}` : '')
        ).then((response) => {
            return response.data;
        }).catch((error) => {
            return error.response;
        });
    }

    async getDirector() {
        return await axios.get(
            //process.env.REACT_APP_DOTNET_API_HOST + `/api/Employee/GetDirector`,
            process.env.REACT_APP_API_HOST + `/employees/director`,
        ).then((response) => {
            var director = {
                name: ""
            }

            //console.log("DIRECTOR DATA: " + JSON.stringify(response.data));

            if (response.status === 200) {
                director.name = response.data.data.nombres + " " + response.data.data.apellidos;
            }

            return director;
        }).catch((error) => {
            return error.response;
        });
    }

    async getSucursal() {
        return axios
            .get(process.env.REACT_APP_API_HOST + "/employees/sucursal");
    }

    async update(employeeData) {
        return axios
            .post(process.env.REACT_APP_API_HOST + "/employees/update", employeeData);
    }

    async forgotPassword(email) {
        return await axios.post(
            process.env.REACT_APP_API_HOST + "/employees/forgot-password", {
            email,
        }
        )
    }

    async getEmployeeGroup(value) {
        return await axios.get(
            process.env.REACT_APP_API_HOST + "/user-group/group/" + value,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getCantEmp() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/cantemp`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getCantEmpCert() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/cantempcert`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getCantEmpCertCor() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/cantempcertcor`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getCantEmpIps() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/cantempips`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }

    async getCantPresSer() {
        return await axios.get(
            process.env.REACT_APP_API_HOST + `/employees/cantpresser`,
        ).then((response) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    }
}
const instance = new EmployeeService();

export default instance;