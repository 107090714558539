import React from 'react';

export const NotificacionesListDetailsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth : ['rh', 'rh_not_signer', 'director', 'funcionario'],
    routes  : [
        {
            path     : '/notificaciones-details/:id?',
            component: React.lazy(() => import('./NotificacionesListDetails'))
        }
    ]
};