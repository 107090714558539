import React from 'react';
//import {authRoles} from '../../auth';

export const UserPatronalesListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['rh', 'rh_not_signer'],
    routes: [{
        path: '/patronales-list',
        component: React.lazy(() =>
            import('./UserPatronalesList'))
    }]
};