import React from 'react';
//import {authRoles} from '../../auth';

export const TiposNotificacionesListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['rh', 'rh_not_signer', 'admin'],
    routes: [{
        path: '/tipo-notificacion',
        component: React.lazy(() =>
            import('./TiposNotificacionesList'))
    }]
};