import React from 'react';

export const Configuraciones = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: ['admin'],
    routes: [{
        path: '/configuraciones',
        component: React.lazy(() =>
            import ('./ConfiguracionesList'))
    }]
};